import { Component } from '@angular/core';
import {environment} from "../../../../environments/environment";
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {
  constructor(private router: Router) {}
  logout() {
    this.router.navigate(['/login']);
  }
  protected readonly environment = environment;
}
