
<div class="row" [matMenuTriggerFor]="profile">
    <div class="col-2">
        <button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5 p-0 d-flex jc-c align-items-center"> <mat-icon style="color: white">account_circle</mat-icon> </button>
    </div>
<!-- Add username -->
    <div class="col-4 d-flex align-items-center" style="display: flex; align-items: center; margin-left: 10px;">
        <span style="color: white; font-size: medium; ">Dawei Zhuang</span>
    </div>
    <mat-menu #profile="matMenu">
        <button mat-menu-item>
            <mat-icon>settings</mat-icon> Settings </button>
        <button mat-menu-item>
            <mat-icon>account_box</mat-icon> Profile </button>
        <button mat-menu-item (click)="logout()">
            <mat-icon>exit_to_app</mat-icon> Logout </button>
    </mat-menu>
</div>
<button mat-button style="color: white">Environment: {{environment.envName}}</button>

