import {ClassProvider, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {FullComponent} from "./layouts/full/full.component";
import {WatchdogMaterialModule} from "./material-module";
import {AppHeaderComponent} from "./layouts/full/header/header.component";
import {AppSidebarComponent} from "./layouts/full/sidebar/sidebar.component";
import {RouterModule} from "@angular/router";
import {AppRoutes} from "./app.routes";
import {SharedModule} from "./shared/shared.module";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {LoggingInterceptor} from "./services/http/LoggingInterceptor";
// import {AuthInterceptor} from "./services/http/auth-interceptor";
import {NgOptimizedImage} from "@angular/common";

const LOGGING_INTERCEPTOR_PROVIDER: ClassProvider = {
  provide: HTTP_INTERCEPTORS ,
  useClass: LoggingInterceptor,
  multi: true
};

// const API_KEY_INTERCEPTOR_PROVIDER: ClassProvider = {
//   provide: HTTP_INTERCEPTORS ,
//   useClass: AuthInterceptor,
//   multi: true
// };


@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppHeaderComponent,
    AppSidebarComponent
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatSlideToggleModule,
        WatchdogMaterialModule,
        RouterModule.forRoot(AppRoutes),
        SharedModule,
        HttpClientModule,
        NgOptimizedImage,
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
