import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { LoginComponent } from './pages/login/login.component';

export const AppRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'admin',
    data: {roles: ['watchdog-admin-console-access']},
    component: FullComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/results/results.module').then(m => m.ResultsModule)
      },
      {
        path: 'build',
        loadChildren: () => import('./pages/build/build.module').then(m => m.BuildModule)
      },
      {
        path: 'schedule',
        loadChildren: () => import('./pages/control/control.module').then(m => m.ControlModule)
      },
      {
        path: 'system-config',
        data: {roles: ['watchdog-admin-console-system-config']},
        loadChildren: () => import('./system-config/system-config.module').then(m => m.SystemConfigModule)
      },
      {
        path: 'metrics',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'admin/dashboard'
  },
];
