<div class="main-container">
    <mat-card style="padding-top: 10px;">
        <mat-card-title>Login</mat-card-title>
        <mat-card-content>
            <div class="login-container" style="display: flex; flex-direction: column; align-items: center;">
                <form [formGroup]="form" (ngSubmit)="submit()" style="width: 100%;">
                    <p>
                        <mat-form-field style="width: 100%;">
                            <input type="text" matInput placeholder="Username" formControlName="username">
                        </mat-form-field>
                    </p>

                    <p>
                        <mat-form-field style="width: 100%;">
                            <input type="password" matInput placeholder="Password" formControlName="password">
                        </mat-form-field>
                    </p>

                    <div class="button" style="margin-bottom: 20px; width: 100%;">
                        <button style="width: 100%;" mat-button mat-raised-button [color]="'primary'" type="submit">Login</button>
                    </div>
                </form>
                <hr style="width: 100%; border-top: 1px solid #ccc; margin: 20px 0;">
                <div style="width: 100%;">
                    <button style="width: 100%;" (click)="submit()" color="warn" mat-button mat-raised-button>Sign in with SSO</button>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
