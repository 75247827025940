import {Injectable} from '@angular/core';

export interface Menu {
    state: string;
    name: string;
    type: string;
    icon: string;
}

@Injectable()
export class MenuItems {
    dummyMenu: Menu = {state: '', type: '', name: '', icon: ''};
    MENU_ITEMS = [
        {state: 'dashboard', type: 'link', name: 'Dashboard', icon: 'fact_check'},
        {state: 'build', type: 'link', name: 'Build', icon: 'build'},
        {state: 'schedule', type: 'link', name: 'Schedule', icon: 'settings'},
        {state: 'metrics', name: 'Metrics', type: 'link', icon: 'assessment'},
        this.dummyMenu,
    ];

    getMenuitem(): Menu[] {
        return this.MENU_ITEMS.filter(menuItem => menuItem.type === 'link');
    }
}
