<div class="main-container">
    <mat-toolbar>
        <button mat-icon-button (click)="snav.toggle()">
            <mat-icon class="mini_menu">menu</mat-icon>
        </button>
        <div class="navbar-header">
            <a class="navbar-brand" href="/">
               <b><img ngSrc="assets/images/logo.png" alt="homepage" class="light-logo" height="72" width="259"></b>
            </a>
        </div>
        <app-header class="d-flex align-items-center ml-auto"></app-header>
    </mat-toolbar>

    <mat-sidenav-container class="watchdog-sidenav-container" autosize>
        <mat-sidenav #snav mode="side" opened id="snav" class="watchdog-sidebar" >
            <app-sidebar></app-sidebar>
<!--            <app-sidebar (mouseleave)="snav.close()"></app-sidebar>-->
        </mat-sidenav>

        <mat-sidenav-content class="page-wrapper">
          <div class="page-content">
                <router-outlet></router-outlet>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
