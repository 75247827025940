import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { WatchdogMaterialModule } from 'src/app/material-module';

@Component({
  selector: 'app-login',
  styleUrl: './login.component.css',
  templateUrl: './login.component.html',
  imports: [CommonModule,
    WatchdogMaterialModule,
    FormsModule,
    ReactiveFormsModule],
    standalone: true,
})
export class LoginComponent {
  form: FormGroup = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });
  constructor(private router: Router) {}

  submit() {
    if (this.form.valid) {
      this.router.navigate(['/admin/results/template-list']);
    }
  }
  @Input() error: string | null;

}
