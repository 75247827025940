import { NgModule } from '@angular/core';

import { MenuItems } from './menu-items/menu-items';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import {MacFormatPipe} from "./mac-format.pipe";
import {JsonPipe, KeyValuePipe, NgForOf, NgIf, SlicePipe, TitleCasePipe} from "@angular/common";
import {MatDialogActions, MatDialogContainer, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {MatButton} from "@angular/material/button";
import {MatProgressBar} from "@angular/material/progress-bar";
import {MatTooltip} from "@angular/material/tooltip";
import { OverlaySpinnerComponent } from './overlay-spinner/overlay-spinner.component';
import {MatProgressSpinner} from "@angular/material/progress-spinner";


@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    MacFormatPipe,
    OverlaySpinnerComponent
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    MacFormatPipe,
    OverlaySpinnerComponent
  ],
  imports: [
    NgIf,
    MatDialogTitle,
    MatButton,
    MatDialogActions,
    MatDialogContainer,
    MatDialogContent,
    KeyValuePipe,
    NgForOf,
    JsonPipe,
    SlicePipe,
    TitleCasePipe,
    MatProgressBar,
    MatTooltip,
    MatProgressSpinner
  ],
  providers: [MenuItems]
})
export class SharedModule { }
